.hints {
    position: absolute;
    top: 120px;
    right: 50%;
    transform: translateX(50%);
    background-color: var(--color-primary);
    color: var(--color-text);
    padding: 15px 25px;
    border-radius: var(--px-border-radius);
}

.hints .hint-text {
    font-size: 1.2rem;
    font-weight: 700;
}

.notification {
    position: absolute;
    top: 5px;
    right: 50%;
    transform: translateX(50%);
    background-color: var(--color-success);
    color: var(--color-text);
    padding: 10px 20px;
    border-radius: var(--px-border-radius);
    border: var(--px-border);
    font-weight: 700;
    z-index: 1000;
    display: none;
}

.notification.show {
    display: block;
}

.game-header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.game-header .item {
    background-color: var(--color-primary);
    color: var(--color-text);
    padding: 10px 20px;
    border-radius: var(--px-border-radius);
    border: var(--px-border);
    cursor: pointer;
    font-weight: 700;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .game-header .item {
        padding: 10px 15px;

    }
    
    .game-header {
        flex-wrap: wrap;
    }

    .game-header .item {
        margin-bottom: 10px;
        width: calc((100% - 20px) / 2);
    }
    
}

.game-header .item.disable {
    background-color: black;
    cursor: not-allowed;
}

.game-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.keyboard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
}

.keyboard .row {
    display: flex;
    justify-content: center;
    align-items: center;;
    margin-bottom: 10px;
}

.keyboard .key {
    background-color: var(--color-primary);
    color: var(--color-text);
    border-radius: var(--px-border-radius);
    border: var(--px-border);
    cursor: pointer;
    font-weight: 700;
    font-size: 1.0rem;
    margin: 0 5px;
    padding: 20px 25px;
}

@media screen and (max-width: 800px) {
    .keyboard  {
        display: none;
    }

    .game-header {
        margin-bottom: 5px;
    }

    .game-header .item {
        font-size: 0.8rem;
    }
    
}

.keyboard .key.yellow {
    background-color: var(--color-warning);
}

.keyboard .key.success {
    background-color: var(--color-success);
}

.keyboard .key.wrong {
    background-color: var(--color-gray);
}

.mobile-keyboard {
    display: none;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
}

@media screen and (max-width: 800px) {
    .mobile-keyboard {
        display: flex;
    }
    
}

.mobile-keyboard .row {
    display: flex;
    justify-content: center;
    align-items: center;;
    margin-bottom: 6px;
}

.mobile-keyboard .key {
    background-color: var(--color-primary);
    color: var(--color-text);
    border-radius: var(--px-border-radius);
    border: var(--px-border);
    cursor: pointer;
    font-weight: 700;
    font-size: .8rem;
    margin: 0 1px;
    padding: 10px 15px;
}

/* style class to added to line if press enter that rotate line */
.game-line.entered {
    animation: rotate 1s linear;
}

@keyframes rotate {
    0% {
        transform: rotateX(0);
    }
    100% {
        transform: rotateX(360deg);
    }
}

/* add wrong class to current line that make a smoth animation */
.game-line.wrong {
    animation: shake 0.5s linear;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}