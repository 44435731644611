
.h2-heading {
    font-size: 1.5rem;
    color: var(--color-secondary);
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 700;
    text-align: center;
    margin: 20px 0;
    margin-bottom: 30px;
    position: relative;
}

.h2-heading::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;   
    height: 2px;
    background: linear-gradient(90deg, transparent, #538d4e, transparent);
}

.logo {
    margin: 50px auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logo h1 {
    position: relative;
    font-size: 2.5rem;
    color: var(--color-secondary);
    text-transform: uppercase;
    letter-spacing: 10px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    transition: color 0.3s;
}

.logo h1::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 2px;
    background: linear-gradient(90deg, transparent, #538d4e, transparent);
}


/* make each span to move up and down infinitave */
.logo h1 span {
    display: inline-block;
    animation: move 1s infinite;
}

.logo h1 span:nth-child(1) {
    animation-delay: 0.1s;
}

.logo h1 span:nth-child(2) {
    animation-delay: 0.2s;
}

.logo h1 span:nth-child(3) {
    animation-delay: 0.4s;
}

.logo h1 span:nth-child(4) {
    animation-delay: 0.6s;
}

.logo h1 span:nth-child(5) {
    animation-delay: 0.8s;
}


@keyframes move {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.logo h1:hover {
    color: var(--color-primary);
}

.logo p {
    font-size: 1rem;
    color: var(--color-secondary);
    text-align: center;
    margin-top: 20px;
}

@media screen and (max-width: 800px) {
    .logo {
        margin: 20px auto;
    }
    .logo h1 {
        font-size: 1.5rem;
        margin-bottom: 8px;
    }
    .logo p {
        font-size: 0.8rem;
    }
}

.select-level {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px auto;
    background-color: var(--color-primary);
    padding: 10px 20px;
    border-radius: var(--px-border-radius);
    border: .5px solid var(--color-secondary);
    margin-bottom: 50px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.select-level button {
    font-size: 1rem;
    color: var(--color-bg);
    background-color: var(--color-secondary);
    outline: none;
    padding: 20px 40px;
    margin: 10px;
    cursor: pointer;
    font-weight: 700;
    border: var(--px-border);
    transition: all .5s ease-in-out;
    border-radius: var(--px-border-radius);
}

.select-level button.start-button {
    background-color: var(--color-success) !important;
    margin-top: 50px;
}

.select-level button.start-button:hover {
    background-color: var(--color-bg) !important;
}

.select-level button.selected {
    background-color: var(--color-primary);
    color: var(--color-secondary);
}

.select-level button.selected:hover {
    background-color: var(--color-secondary);
    color: var(--color-bg);
}

.select-level button:hover {
    background-color: var(--color-primary);
    color: var(--color-secondary);
    transform: translate(0, -5px);
}

.select-level .level-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.how-to-play {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-primary);
    padding: 10px 20px;
    border-radius: var(--px-border-radius);
    border: .5px solid var(--color-secondary);
    margin-bottom: 50px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.how-to-play p {
    font-size: 1rem;
    color: var(--color-secondary);
    text-align: center;
    margin-bottom: 20px;
}

.how-to-play div {
    animation: move 5s infinite;
}

.how-to-play h5 {
    font-size: 1rem;
    color: var(--color-text);
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
}

.how-to-play h5 b {
    color: var(--color-secondary);
    font-weight: 800;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px auto;
}

.footer p, .footer a {
    font-size: 1rem;
    color: var(--color-secondary);
    text-align: center;
    margin-bottom: 10px;
}

/* general style of letters */
.letter {
    font-size: 2rem;
    color: var(--color-secondary);
    text-align: center;
    margin: 5px;
    cursor: pointer;
    border: 1px solid var(--color-secondary);
    width: 90px;
    height: 90px;
    overflow: hidden;
    text-align: center;
    display: inline-block;
    display: inline-block;
    padding: 5px;
    font-weight: 800;
    font-size: 4rem;
    border-radius: 3px;
    text-transform: uppercase;
    transition: all .5s ease-in-out;
}

@media screen and (max-width: 800px) {
    .letter {
        font-size: 1.5rem;
        width: 50px;
        height: 50px;
        padding: 5px;
        font-weight: 800;
        font-size: 2rem;
        border-radius: 3px;
        text-transform: uppercase;
    }
    
}

.letter.filled {
    background-color: var(--color-secondary);
    color: var(--color-bg);
    border: none;
    animation: pop 0.3s;
}

@keyframes pop {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.how-to-play .letter {
    font-size: 1.5rem;
    width: 50px;
    height: 50px;
    padding: 5px;
    font-weight: 800;
    font-size: 2rem;
    border-radius: 3px;
    text-transform: uppercase;
}

.letter.green-letter {
    background-color: var(--color-success);
    color: var(--color-text);
    border: none;
}

.letter.yellow-letter {
    background-color: var(--color-warning);
    color: var(--color-text);
    border: none;
}

.letter.gray-letter {
    background-color: var(--color-gray);
    color: var(--color-text);
    border: none;
}