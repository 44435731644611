* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-bg);
  color: var(--color-text);
}

.container {
  width: 800px;
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  .container {
    width: 95%;
    padding: 0 20px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 95%;
    padding: 0 10px !important;
  }
}

@media screen and (max-width: 400px) {
  .container {
    width: 98%;
    padding: 0 5px !important;
  }
  
}

:root {
  --color-primary: #5C5470;
  --color-secondary: #B9B4C7;
  --color-bg: #352F44;
  --color-gray: gray;
  --color-text: #FAF0E6;
  --color-success: #28a745;
  --color-danger: #dc3545;
  --color-warning: #ffc107;
  --color-info: #17a2b8;
  --px-border-radius: 12px;
  --px-border: 1px solid var(--color-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
